<template>
	<router-view></router-view>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'SuperAdmin',
	mixins: [SuperAdminModuleGuard]
}
</script>
